import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2ec63d34 = () => interopDefault(import('../pages/book-teaching-sessions/index.vue' /* webpackChunkName: "pages/book-teaching-sessions/index" */))
const _e076b0ba = () => interopDefault(import('../pages/change-subscription/index.vue' /* webpackChunkName: "pages/change-subscription/index" */))
const _f15fcf84 = () => interopDefault(import('../pages/get-extra-sessions/index.vue' /* webpackChunkName: "pages/get-extra-sessions/index" */))
const _b4a5a522 = () => interopDefault(import('../pages/get-teaching-sessions/index.vue' /* webpackChunkName: "pages/get-teaching-sessions/index" */))
const _72e6bb7d = () => interopDefault(import('../pages/iris.vue' /* webpackChunkName: "pages/iris" */))
const _43e5f040 = () => interopDefault(import('../pages/iris/index.vue' /* webpackChunkName: "pages/iris/index" */))
const _2d763a87 = () => interopDefault(import('../pages/iris/presentations.vue' /* webpackChunkName: "pages/iris/presentations" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _71a5f96e = () => interopDefault(import('../pages/members-area/index.vue' /* webpackChunkName: "pages/members-area/index" */))
const _7de68bc4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/book-teaching-sessions",
    component: _2ec63d34,
    name: "book-teaching-sessions"
  }, {
    path: "/change-subscription",
    component: _e076b0ba,
    name: "change-subscription"
  }, {
    path: "/get-extra-sessions",
    component: _f15fcf84,
    name: "get-extra-sessions"
  }, {
    path: "/get-teaching-sessions",
    component: _b4a5a522,
    name: "get-teaching-sessions"
  }, {
    path: "/iris",
    component: _72e6bb7d,
    children: [{
      path: "",
      component: _43e5f040,
      name: "iris"
    }, {
      path: "presentations",
      component: _2d763a87,
      name: "iris-presentations"
    }]
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/logout",
    component: _49c57cf4,
    name: "logout"
  }, {
    path: "/members-area",
    component: _71a5f96e,
    name: "members-area"
  }, {
    path: "/privacy-policy",
    component: _7de68bc4,
    name: "privacy-policy"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
